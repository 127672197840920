export const format = (num, label = "") => {
    let ending = label === "" ? "" : " " + label;
    return !isNaN(num)
        ? Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K" + ending
            : Math.sign(num) * Math.abs(num).toFixed(2) + ending
        : "N/A";
};

export const formatCoordinatesLat = (deg) => {

    // console.log("lat", lat)
    let min = (deg - Math.trunc(deg)) * 60;

    // console.log("minLat", minLat)
    let sec = (min - Math.trunc(min)) * 60;

    // console.log("secLat", secLat)

    min = (min > 0) ? min : (min * -1);
    sec = (sec > 0) ? sec : (sec * -1);
    let degAbs = (deg > 0) ? deg : (deg * -1);

    return (degAbs < 10 ? "  " : "") + (degAbs < 100 ? " " : "")  + Math.trunc(deg) + "°" + Math.trunc(min) + "'" + Math.trunc(sec * 10)/10 + '"' + (deg < 0 ? " S" : "  N");

};

export const formatCoordinatesLon = (deg) => {

    // console.log("lat", lat)
    let min = (deg - Math.trunc(deg)) * 60;

    // console.log("minLat", minLat)
    let sec = (min - Math.trunc(min)) * 60;

    // console.log("secLat", secLat)

    min = (min > 0) ? min : (min * -1);
    sec = (sec > 0) ? sec : (sec * -1);
    let degAbs = (deg > 0) ? deg : (deg * -1);

    return (degAbs < 10 ? "  " : "") + (degAbs < 100 ? " " : "")  + Math.trunc(deg) + "°" + Math.trunc(min) + "'" + Math.trunc(sec * 10)/10 + '"' + (deg < 0 ? " W" : " E");

};